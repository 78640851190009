import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import WeeksComponent from "./WeeksComponent";
import weeksService from '../_services/weeks.service';

import { weeksActions, userActions, pickActions, gamesActions } from "../_actions";
// import { gamesActions } from "../_actions";
import GamesComponent from "./GamesComponent";

const HomePage = (props) => {
  const user = useSelector((state) => state.authentication.user);
  const games = useSelector((state) => state.games);
  const userPicks = useSelector((state) => state.picks) //look into this later
  // const weeks = useSelector((state) => state.weeks); //look into this later

  const [currentWeek, setCurrentWeek] = useState('1');
  const [dates, setDates] = useState([]);
  const [todayWeekNumber, setTodayWeekNumber] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getAll());
    dispatch(gamesActions.getAll());
    dispatch(pickActions.getUserPicks(user.id));

    async function handleWeeks() {
      console.log('running');
      const weeks = await weeksService.getAll();
      if (weeks) {
        let firstWeek = new Date(weeks[0].date);
        firstWeek.setDate(firstWeek.getDate() + 1);
        let startWeek = firstWeek
        let lastWeek = new Date(weeks[17].date);
        lastWeek.setDate(lastWeek.getDate() + 1);
        let tempDates = [new Date(firstWeek)];

        while (startWeek < lastWeek) {
          startWeek.setDate(startWeek.getDate() + 7);
          const newDate = new Date(startWeek);
          tempDates.push(newDate);
        }
        setDates(weeks);
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        let currentWeekNumber = '';
        tempDates.every((date, index) => {
          if (todayDate <= date) {
            currentWeekNumber = (index + 1).toString();
            return false;
          }
          return true;
        });
        if (!currentWeekNumber) {
          currentWeekNumber = 18;
        }
        setTodayWeekNumber(currentWeekNumber);
        setCurrentWeek(currentWeekNumber);
      }
    }

    handleWeeks();
  }, [user.id, dispatch]);




  return (
    <div>
      <Container fluid className="main-container">
        <Row>
          <Col md={3} sm={3} xs={3} className="weekCol">
            {dates.length > 0 && <WeeksComponent setCurrentWeek={setCurrentWeek} weeks={dates} currentWeek={currentWeek} todayWeekNumber={todayWeekNumber}></WeeksComponent>}
          </Col>
          <Col>
            <Row>
              {userPicks.items && games.items && dates.length > 0 && user && <GamesComponent week={currentWeek} sundays={dates} games={games} user={user} userPicks={userPicks}></GamesComponent>}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
