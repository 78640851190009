import { gamesConstants } from '../_constants';
import gamesService from '../_services/games.service';

export const gamesActions = {
    getAll,
    getAllWeeks,
    delete: _delete
};



function getAll() {
    return dispatch => {
        dispatch(request());

        gamesService.getAll()
            .then(
                games => dispatch(success(games)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: gamesConstants.GETALL_REQUEST } }
    function success(games) { return { type: gamesConstants.GETALL_SUCCESS, games } }
    function failure(error) { return { type: gamesConstants.GETALL_FAILURE, error } }
}

function getAllWeeks() {
    return dispatch => {
        dispatch(request());

        gamesService.getAllWeeks()
            .then(
                weeks => dispatch(success(weeks)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: gamesConstants.GETALL_WEEKS_REQUEST } }
    function success(weeks) { return { type: gamesConstants.GETALL_WEEKS_SUCCESS, weeks } }
    function failure(error) { return { type: gamesConstants.GETALL_WEEKS_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        gamesService.delete(id)
            .then(
                game => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: gamesConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: gamesConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: gamesConstants.DELETE_FAILURE, id, error } }
}