
import { Modal, Button } from 'react-bootstrap'
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickActions } from "../_actions";




const UserPicksModal = (props) => {

    const gameId = props.game_id;
    const usersGamePicks = useSelector((state) => state.picks.usersPicks) //look into this later


    const dispatch = useDispatch();

    useEffect(() => {
        if (gameId) {
            dispatch(pickActions.getGamePicks(gameId))
        }
    }, [gameId]);



    return (
        <>
            {usersGamePicks && <Modal Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Other Users Picks:

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>                    {usersGamePicks.map((pick, index) => {
                    return <div key={index}>
                        <div style={{ display: 'flex' }}>{pick.user}: <span style={{ marginLeft: 'auto' }}>{pick.winner} {pick.double ? 'x2' : ''}</span></div>
                    </div>
                })
                }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>}
        </>

    )
}

export default UserPicksModal