import { picksConstants } from '../_constants';
import picksService from '../_services/picks.service';
import { alertActions } from '.';

export const pickActions = {
    addMultiple,
    getUserPicks,
    getGamePicks,
    getAll,
    delete: _delete
};

function addMultiple(payload) {
    return dispatch => {
        picksService.addMultiple(payload)
            .then(
                picks => { 
                    dispatch(alertActions.success(`Picks: ${picks}`));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

function getUserPicks(id) {
    return dispatch => {
        dispatch(request());
        picksService.getUserPicks(id)
            .then(
                picks => dispatch(success(picks)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: picksConstants.GETUSER_REQUEST } }
    function success(picks) { return { type: picksConstants.GETUSER_SUCCESS, picks } }
    function failure(error) { return { type: picksConstants.GETUSER_FAILURE, error } }
}

function getGamePicks(id) {
    return dispatch => {
        dispatch(request());
        picksService.getGamePicks(id)
            .then(
                usersPicks => dispatch(success(usersPicks)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: picksConstants.GETGAMES_REQUEST } }
    function success(usersPicks) { return { type: picksConstants.GETGAMES_SUCCESS, usersPicks } }
    function failure(error) { return { type: picksConstants.GETGAMES_FAILURE, error } }
}



function getAll() {
    return dispatch => {
        dispatch(request());
        picksService.getAll()
            .then(
                picks => dispatch(success(picks)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: picksConstants.GETALL_REQUEST } }
    function success(picks) { return { type: picksConstants.GETALL_SUCCESS, picks } }
    function failure(error) { return { type: picksConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        picksService.delete(id)
            .then(
                pick => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: picksConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: picksConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: picksConstants.DELETE_FAILURE, id, error } }
}