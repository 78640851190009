export const gamesConstants = {
   
    GETALL_REQUEST: 'GAMES_GETALL_REQUEST',
    GETALL_SUCCESS: 'GAMES_GETALL_SUCCESS',
    GETALL_FAILURE: 'GAMES_GETALL_FAILURE',

    GETALL_WEEKS_REQUEST: 'GAMES_GETALL_WEEKS_REQUEST',
    GETALL_WEEKS_SUCCESS: 'GAMES_GETALL_WEEKS_SUCCESS',
    GETALL_WEEKS_FAILURE: 'GAMES_GETALL_WEEKS_FAILURE',

    DELETE_REQUEST: 'GAMES_DELETE_REQUEST',
    DELETE_SUCCESS: 'GAMES_DELETE_SUCCESS',
    DELETE_FAILURE: 'GAMES_DELETE_FAILURE'    
};
