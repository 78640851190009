import { authHeader } from '../_helpers';
import userService from './user.service'
const API_PREFIX = process.env.NODE_ENV === 'production' ? 'https://wr27v8krr0.execute-api.us-east-1.amazonaws.com/dev' : '';


const gamesService = {
    getAll,
    getAllWeeks,
    getById,
    loadGames,
    calculateResults,
    weekWinner,
    getScores,
    delete: _delete
};



function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/games`, requestOptions).then(handleResponse);
}

function getAllWeeks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/weeks`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/games/${id}`, requestOptions).then(handleResponse);
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/games/${id}`, requestOptions).then(handleResponse);
}

function loadGames() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${API_PREFIX}/api/loadGames`, requestOptions).then(handleResponse);
}

function calculateResults(week) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/calculateResults?` + new URLSearchParams({ weekNumber: week }), requestOptions).then(handleResponse);
}

function weekWinner(week) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/calculateWeekWinner?` + new URLSearchParams({ weekNumber: week }), requestOptions).then(handleResponse);
}

function getScores(date) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(`https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?dates=${date}`).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export default gamesService;