import React from "react";
import { Container } from "react-bootstrap";

const RulesPage = (props) => {


    return (
        <div className="homeHeader">
            <div className="homeTitle">
                Welcome to NFL Pick Em'
            </div>
            <div className="homeIntro mt-5">
                Below are the layout and rules of this competition:<br />
                <ul className="text-left">
                    <li>Only Picking Sunday Games ATS</li>
                    <li>One Double Per Week On Most Confident Game (only counts as 2 wins, not 2 losses)</li>
                    <li>Push Games Don't Count For Win Or Loss</li>
                    <li>Game Lines Come From Established Bookmaker API</li>
                    <li>Game Picks and Lines Locked In at 12:00am Sunday Each Week</li>
                    Payouts:
                    <li>$10 Buy In Per Week ($180 Total) + Small Entry Fee (Servers and Domain Name Costs)</li>
                    <li>Best Record Each Week Wins 1/2 Weekly Buy In</li>
                    <li>(Best Weekly Record = Highest (Ws - Ls), Ties Split Pot</li>
                    <li>Best Overall Record Wins Other Half Of Cumulative Weekly Buy In</li>
                    <li>Collections and Payouts Will Be Done After Week 18</li>
                    Example: 10 People, $10 a Weeek, 18 Weeks, = $1800 Pot <br />
                    Weekly Winners Gets $50/week
                    Year End Winner Gets $900
                </ul>
            </div>
        </div>
    );
};

export default RulesPage;
