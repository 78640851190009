import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";



const NavBar = (props) => {

    const user = useSelector((state) => state.authentication.user);


    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">NFL Sunday Weekly Pickem</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Weeks</Nav.Link>
                        <Nav.Link href="/leaderboard">Leader Board</Nav.Link>
                        <Nav.Link href="/rules">Rules</Nav.Link>
                    </Nav>
                    <Nav>
                        <Navbar.Brand>Hi {user.firstName}! Record: {user.wins}-{user.losses} Earnings: {user.netEarnings < 0 ? `-$${user.netEarnings * -1}` : `$${user.netEarnings}`}</Navbar.Brand>
                        <Nav.Link href="/login">
                            Logout
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;
