import React, { useEffect, useState } from "react";
import { Row, Card, Button, Form, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { pickActions } from "../_actions"
import SuccessModal from "./SuccessModal";
import UserPicksModal from "./UserPicksModal";
import gamesService from "../_services/games.service";
import ScoreBox from "./ScoreBox";
import userService from "../_services/user.service";
import picksService from '../_services/picks.service';




const GamesComponent = (props) => {
    const [games, setGames] = useState([]);
    const [gameArray, setGameArray] = useState([]);
    const [hasDoubledGame, setHasDoubledGame] = useState(false);
    const [weekWins, setWeekWins] = useState(0);
    const [weekLosses, setWeekLosses] = useState(0);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showUserPicksModal, setShowUserPicksModal] = useState(false);
    const [userPicksGameId, setUserPicksGameId] = useState('');
    const [inProgressGames, setInProgressGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const week = props.week;
    const user = props.user;
    const sundays = props.sundays;
    const allGames = props.games.items;
    // const userPicks = props.userPicks.items;
    const users = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const [isLocked, setIsLocked] = useState(false);
    const [userPicks, setUserPicks] = useState(props.userPicks.items);
    const [selectedUser, setSelectedUser] = useState(user);

    useEffect(() => {
        let today = new Date();
        console.log(today);
        //lock games if today is past or on currently selected sunday 
        let tempLocked = false;

        console.log(new Date(sundays[week - 1].date.toUTCString())
        )

        const newDate = new Date(sundays[week - 1].date.toUTCString());
        newDate.setHours(newDate.getHours() + 4);

        if (today >= newDate) {
            setIsLocked(true);
            tempLocked = true;
        } else {
            setIsLocked(false);;
            tempLocked = false;
            setSelectedUser(user);
        }

        if (allGames && userPicks && !(selectedUser.id !== user.id && !tempLocked)) {
            let newGames = [...games];
            newGames = allGames.filter((game) => {
                return game.week === week.toString();
            });
            let pickPayloadArray = []
            let tempWins = 0;
            let tempLosses = 0;
            newGames.forEach((game) => {
                game.homeTeamButtonVariant = 'secondary';
                game.awayTeamButtonVariant = 'secondary';
                game.showDouble = true;
                game.isDoubled = false;
                userPicks.forEach((pick) => {
                    if (game.id === pick.gameId) {
                        if (pick.result) {
                            if (pick.double) {
                                tempWins += 2;
                            } else {
                                tempWins++;
                            }
                        } else if (pick.result === false) {
                            tempLosses++;
                        };

                        if (pick.winner === game.homeTeam) {
                            game.homeTeamButtonVariant = 'primary';
                            if (pick.result) {
                                game.homeTeamButtonVariant = 'success';
                            } if (pick.result === false) {
                                game.homeTeamButtonVariant = 'danger';
                            }
                        } else {
                            game.awayTeamButtonVariant = 'primary';
                            if (pick.result) {
                                game.awayTeamButtonVariant = 'success';
                            } if (pick.result === false) {
                                game.awayTeamButtonVariant = 'danger';
                            }
                        }
                        if (pick.double) {
                            game.showDouble = true;
                            game.isDoubled = true;
                            setHasDoubledGame(true);
                        }
                        let pickObject = { "game": game, "winner": pick.winner };
                        pickPayloadArray.push(pickObject);
                    }
                });
                if (hasDoubledGame && !game.isDoubled) {
                    game.showDouble = false;
                }
                setWeekWins(tempWins);
                setWeekLosses(tempLosses);
            })
            const sortedGames = newGames.sort(function (a, b) {
                return new Date(a.commenceTime) - new Date(b.commenceTime);
            });
            setGames(sortedGames);
            setGameArray(userPicks)
        }
        sundays.forEach(days => {
            const sunday = days.date;
            if (`${sunday.getMonth()}/${sunday.getDate() + 1}` === `${today.getMonth()}/${today.getDate()}`) {
                let formatedMonth = ("0" + (today.getMonth() + 1)).slice(-2);
                let formatedDate = ("0" + today.getDate()).slice(-2);
                let sundayDate = `${today.getFullYear()}${formatedMonth}${formatedDate}`;
                gamesService.getScores(sundayDate).then((response) => {
                    let games = response.events;
                    let inProgressGames = games.filter(game => {
                        if (game.status.type.name === 'STATUS_IN_PROGRESS' || game.status.type.name === 'STATUS_HALFTIME') {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    createInProgressGames(inProgressGames);
                })
            }
            setLoading(false);
        })

    }, [allGames, week, hasDoubledGame, sundays, userPicks]);

    useEffect(async () => {
        const selectedUsersPicks = await picksService.getUserPicks(selectedUser.id);
        setUserPicks(selectedUsersPicks);

    }, [selectedUser])


    function scoreRefresh() {
        let today = new Date();

        sundays.forEach(days => {
            const sunday = days.date;
            if (`${sunday.getMonth()}/${sunday.getDate() + 1}` === `${today.getMonth()}/${today.getDate()}`) {
                let formatedMonth = ("0" + (today.getMonth() + 1)).slice(-2);
                let formatedDate = ("0" + today.getDate()).slice(-2);
                let sundayDate = `${today.getFullYear()}${formatedMonth}${formatedDate}`;
                gamesService.getScores(sundayDate).then((response) => {
                    let games = response.events;
                    let inProgressGames = games.filter(game => {
                        if (game.status.type.name === 'STATUS_IN_PROGRESS' || game.status.type.name === 'STATUS_HALFTIME') {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    createInProgressGames(inProgressGames);
                })
            }
        })
    }

    useEffect(() => {
        scoreRefresh()
        const interval = setInterval(() => {
            scoreRefresh()
        }, 10000)
        return () => clearInterval(interval)
    }, [])

    const createInProgressGames = (games) => {
        let gamesArray = [];
        games.forEach((game) => {
            let homeTeamObj = game.competitions[0].competitors[0].homeAway === 'home' ? game.competitions[0].competitors[0] : game.competitions[0].competitors[1];
            let awayTeamObj = game.competitions[0].competitors[0].homeAway === 'away' ? game.competitions[0].competitors[0] : game.competitions[0].competitors[1];
            let inProgressGame = {
                homeTeam: homeTeamObj.team.displayName,
                homeTeamLogo: homeTeamObj.team.logo,
                homeTeamScore: homeTeamObj.score,
                homeTeamColor: homeTeamObj.team.color,
                homeTeamId: homeTeamObj.id,
                awayTeamId: awayTeamObj.id,
                awayTeam: awayTeamObj.team.displayName,
                awayTeamLogo: awayTeamObj.team.logo,
                awayTeamScore: awayTeamObj.score,
                awayTeamColor: awayTeamObj.team.color,
                time: game.competitions[0].status.type.detail,
                situation: game.competitions[0].situation.downDistanceText,
                possessionTeamId: game.competitions[0].situation.possession === homeTeamObj.id ? homeTeamObj.team.displayName : awayTeamObj.team.displayName
            };
            gamesArray.push(inProgressGame);
        })
        setInProgressGames(gamesArray);
    }

    const displayMascot = (team) => {
        let stringArr = team.split(' ')
        return stringArr[stringArr.length - 1];
    }

    const handlePick = (pickedGame, isHome) => {
        let newGames = [...games];
        let newPickedGame = newGames.filter((game) => {
            return pickedGame.id === game.id;
        })
        let winner = '';
        if (isHome) {
            newPickedGame[0].homeTeamButtonVariant = "primary"
            newPickedGame[0].awayTeamButtonVariant = "secondary"
            winner = pickedGame.homeTeam;
        } else {
            newPickedGame[0].awayTeamButtonVariant = "primary"
            newPickedGame[0].homeTeamButtonVariant = "secondary"
            winner = pickedGame.awayTeam;
        }

        setGames(newGames);

        let picObj = {
            double: pickedGame.isDoubled,
            userId: user.id,
            gameId: pickedGame.id,
            week: pickedGame.week,
            winner: winner
        }

        let newGameArray = [...gameArray];
        let newGameObj = newGameArray.filter((game) => {
            return pickedGame.id === game.gameId;
        })
        if (newGameObj.length > 0) {
            newGameObj[0].winner = picObj.winner;
            newGameObj[0].double = picObj.double;
            setGameArray(newGameArray)
        } else {
            setGameArray([...gameArray, picObj]);
        }

    }

    const handleDouble = (pickedGame) => {
        let newGames = [...games];
        let newPickedGame = newGames.filter((game) => {
            return pickedGame.id === game.id;
        })
        let newGameArray = [...gameArray];
        let newGameObj = newGameArray.filter((game) => {
            return pickedGame.id === game.gameId;
        })

        if (!pickedGame.isDoubled) {
            newPickedGame[0].isDoubled = true;
            newGames.forEach((game) => {
                if (game.id !== pickedGame.id) {
                    game.showDouble = false;
                }
            });
            if (newGameObj.length > 0) {
                newGameObj[0].double = true;
            }
        } else {
            newPickedGame[0].isDoubled = false;
            newGames.forEach((game) => {
                game.showDouble = true;
            });
            if (newGameObj.length > 0) {
                newGameObj[0].double = false;
            }
        }

        setGames(newGames);
        setGameArray(newGameArray);
    }

    async function submitPicks() {
        let today = new Date();
        //lock games if today is past or on currently selected sunday 
        const newDate = new Date(sundays[week - 1].date.toUTCString());
        newDate.setHours(newDate.getHours() + 4);

        if (today >= newDate) {

            console.log('Picks are locked');
        } else {

            try {
                await picksService.addMultiple(gameArray)
                setShowSuccessPopUp(true)
            } catch (error) {
                console.log(error);
            }
        }


    }

    function loadResults() {
        gamesService.calculateResults(week);
        setShowSuccessPopUp(true)
    }

    function weekWinner() {
        gamesService.weekWinner(week);
        setShowSuccessPopUp(true)
    }

    function weekRecords() {
        userService.updateAllWeekRecords(week);
        setShowSuccessPopUp(true);
    }

    function showUsersPicks(game) {
        setUserPicksGameId(game.id);
        setShowUserPicksModal(true);
    }

    function openESPNScore() {
        window.open(`https://www.espn.com/nfl/scoreboard/_/week/${week}/year/2022/seasontype/2`, "_blank")
    }

    function showLiveScore(game) {
        let returnGame = false;
        let returnVal = inProgressGames.some(inProgressGame => {
            if (inProgressGame.homeTeam === game.homeTeam) {
                returnGame = inProgressGame
                return inProgressGame;
            }
            return false;
        });
        return returnGame;
    }




    return (
        <>
            <Row className="weekRow">
                {isLocked && <div>Games Are Locked!</div>}
            </Row>
            <Row className="weekRow">
                {isLocked && userPicks.length > 0 && <div>Week Record {weekWins} - {weekLosses}</div>}
            </Row>
            {isLocked && !users.loading && <Row className="weekRow">
                <Dropdown className="d-inline">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                        {`${selectedUser.firstName} ${selectedUser.lastName}`}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {users.items.map((user, index) => {
                            return <Dropdown.Item key={index} onClick={(e) => setSelectedUser(user)}>{`${user.firstName} ${user.lastName}`}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Row>}
            <Row className="weekRow">
                <Button variant="link" onClick={(e) => openESPNScore()}>See Live Scores</Button>
            </Row>
            <Row className="gamesRow">
                {games && userPicks && !loading && games.map((game, index) => {
                    return <div className="gameCard" key={index}>
                        <Card style={{ width: '18rem' }} className={isLocked ? "cardLocked" : "card"}>
                            <Card.Body>
                                <Card.Title>{game.homeTeam} vs. {game.awayTeam}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{displayMascot(game.homeTeam)} {game.line > 0 ? `+ ${game.line}` : game.line}</Card.Subtitle>
                                {game.commenceTime && <Card.Subtitle className="mb-2 text-muted">{new Date(game.commenceTime).toLocaleTimeString([], { timeStyle: 'short' })}</Card.Subtitle>}
                                {game.homeTeamScore && game.awayTeamScore && <Card.Text className="scoreBox">
                                    <ScoreBox game={game}></ScoreBox>

                                </Card.Text>}
                                {showLiveScore(game) !== false && <Card.Text className="scoreBox">
                                    <ScoreBox game={showLiveScore(game)}></ScoreBox>
                                </Card.Text>}
                                <div className="pickButtons">
                                    <Button disabled={isLocked} className="pickButton" variant={game.homeTeamButtonVariant} onClick={(e) => handlePick(game, true)}>
                                        <img src={game.homeTeamLogo} alt="hometeam" className="mr-1 logo"></img>
                                        {game.line > 0 ? `+ ${game.line}` : game.line}</Button>
                                    <Button disabled={isLocked} className="pickButton" variant={game.awayTeamButtonVariant} onClick={(e) => handlePick(game, false)}>
                                        <img src={game.awayTeamLogo} alt="awayteam" className="mr-1 logo"></img>
                                        {game.line * -1 > 0 ? `+ ${game.line * -1}` : game.line * -1}</Button>
                                </div>
                                {game.showDouble && <Form.Check disabled={isLocked} type="checkbox" label="Double?" checked={game.isDoubled} onChange={(e) => handleDouble(game)}></Form.Check>}
                                {isLocked && <Button className="seePicksButton" size="sm" variant="outline-info" onClick={(e) => showUsersPicks(game)}>See All Picks</Button>}
                            </Card.Body>
                        </Card>
                    </div>
                })}

            </Row>
            {games.length > 0 && userPicks && !isLocked && <Row>
                <Button variant="primary" onClick={(e) => submitPicks()}>Submit Picks</Button>
            </Row>}
            {user.firstName === "Will" && user.lastName === "Cricchi" && <div className="ml-2">
                <Button variant="primary ml-3" onClick={(e) => loadResults()}>Load Results</Button>
                <Button className="ml-3" variant="primary" onClick={(e) => weekWinner()}>Week Winner</Button>
                <Button className="ml-3" variant="primary" onClick={(e) => weekRecords()}>Update Week Records</Button>
            </div>}
            <SuccessModal show={showSuccessPopUp} title={"Picks Submitted"} onHide={() => setShowSuccessPopUp(false)}></SuccessModal>
            <UserPicksModal show={showUserPicksModal} onHide={() => setShowUserPicksModal(false)} game_id={userPicksGameId}></UserPicksModal>


        </>
    );


};


export default GamesComponent;
