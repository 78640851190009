import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./_helpers";
import { alertActions } from "./_actions";
import { PrivateRoute } from "./_components";
import HomePage from "./components/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import LeaderBoardPage from "./components/LeaderBoardPage";
import RulesPage from "./components/RulesPage";
import NavBar from "./components/NavBar";
import './App.css';


function App() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, []);

  return (
    <div className="App">
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}
      {localStorage.getItem('user') && <NavBar></NavBar>}
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/leaderboard" component={LeaderBoardPage} />
          <PrivateRoute exact path="/rules" component={RulesPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
