export const picksConstants = {
   
    GETALL_REQUEST: 'PICKS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PICKS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PICKS_GETALL_FAILURE',

    ADDMULTIPLE_REQUEST: 'PICKS_ADDMULTIPLE_REQUEST',
    ADDMULTIPLE_SUCCESS: 'PICKS_ADDMULTIPLE_SUCCESS',
    ADDMULTIPLE_FAILURE: 'PICKS_ADDMULTIPLE_FAILURE',

    GETUSER_REQUEST: 'PICKS_GETUSER_REQUEST',
    GETUSER_SUCCESS: 'PICKS_GETUSER_SUCCESS',
    GETUSER_FAILURE: 'PICKS_GETUSER_FAILURE',

    GETGAMES_REQUEST: 'PICKS_GETGAMES_REQUEST',
    GETGAMES_SUCCESS: 'PICKS_GETGAMES_SUCCESS',
    GETGAMES_FAILURE: 'PICKS_GETGAMES_FAILURE',

    DELETE_REQUEST: 'PICKS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PICKS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PICKS_DELETE_FAILURE'    
};
