import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";

const WeeksComponent = (props) => {
    const users = useSelector((state) => state.users);

    const weeks = props.weeks;
    const [weeksList, setWeeksList] = useState([]);
    const currentWeek = props.currentWeek;
    const todayWeekNumber = props.todayWeekNumber

    useEffect(() => {
        let newWeeksList = [...weeks]
        newWeeksList.forEach((week) => {
            week.date = new Date(week.date);
            week.isActive = false;
        })
        newWeeksList[currentWeek - 1].isActive = true;
        props.setCurrentWeek(newWeeksList[currentWeek - 1].weekNumber);
        setWeeksList(newWeeksList);
    }, [currentWeek]);

    const handlePick = (e, week) => {
        let newWeeksList = [...weeksList]
        newWeeksList.forEach((week) => {
            week.isActive = false;
        })
        newWeeksList[week].isActive = true;
        props.setCurrentWeek(newWeeksList[week].weekNumber);
        setWeeksList(newWeeksList);
    }

    function displaySingleUser(userId) {
        let result = users.items.filter(user => userId.toString() === user.id.toString());
        let winnerString = ''
        result.forEach((winner) => winnerString = winnerString + `- ${winner.firstName} ${winner.lastName}`)
        return winnerString;
    }

    return (
        <div>
            <ListGroup as="ul">
                {!users.loading && weeksList.map((week, index) => {
                    return <ListGroup.Item className={parseInt(week.weekNumber) < parseInt(todayWeekNumber) ? "weekButtonPassed" : "weekButton"} as="li" active={week.isActive} onClick={(e) => handlePick(e, index)} key={week.weekNumber}>
                        <div>Week {week.weekNumber}: {weeks[week.weekNumber - 1].date.getMonth() + 1}/{weeks[week.weekNumber - 1].date.getDate() + 1}{displaySingleUser(week.winnerId)}</div>
                    </ListGroup.Item>
                })}
            </ListGroup>
        </div>
    );


};

export default WeeksComponent;
