import { authHeader } from '../_helpers';
import userService from './user.service'
const API_PREFIX = process.env.NODE_ENV === 'production' ? 'https://wr27v8krr0.execute-api.us-east-1.amazonaws.com/dev' : '';

const getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/weeks`, requestOptions).then(handleResponse);
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
const weeksService = {
    getAll,
};

export default weeksService;