import { authHeader } from '../_helpers';
import userService from './user.service'
const API_PREFIX = process.env.NODE_ENV === 'production' ? 'https://wr27v8krr0.execute-api.us-east-1.amazonaws.com/dev' : '';

const getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks`, requestOptions).then(handleResponse);
}
const addMultiple = (payload) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${API_PREFIX}/api/picks/addMultiple`, requestOptions).then(handleResponse);
}

const getUserPicks = (userId) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks/user/${userId}`, requestOptions).then(handleResponse);
}

const getAllUserPicksByWeek = (weekId) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks/weekPicks/${weekId}`, requestOptions).then(handleResponse);
}

const getGamePicks = (gameId) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks/game/${gameId}`, requestOptions).then(handleResponse);
}

const getById = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks/${id}`, requestOptions).then(handleResponse);
}


// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_PREFIX}/api/picks/${id}`, requestOptions).then(handleResponse);
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

const picksService = {
    getAll,
    addMultiple,
    getUserPicks,
    getGamePicks,
    getAllUserPicksByWeek,
    getById,
    delete: _delete
};

export default picksService;