

const ScoreBox = (props) => {
    const game = props.game;
    const homeColor = props.game.homeTeamColor;
    const awayColor = props.game.awayTeamColor;
    const homeStyle = {
        color: "#" + homeColor
    }
    const awayStyle = {
        color: "#" + awayColor
    }
    return (
        <div className="bg-white">
            {game.time && <div>
                <div>{game.time}</div>
                <div>Possession: {game.possessionTeamId}</div>
                <div>{game.situation}</div>
            </div>}
            <div className="text-left" style={homeStyle}>
                <img src={game.homeTeamLogo} alt="hometeam" className="mr-3 logo"></img>
                {game.homeTeam}: {game.homeTeamScore}
            </div>
            <div className="text-left" style={awayStyle}>
                <img src={game.awayTeamLogo} alt="awayteam" className="mr-3 logo"></img>
                {game.awayTeam}: {game.awayTeamScore}
            </div>
        </div>
    )
};
export default ScoreBox;
