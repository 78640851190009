import React, { useEffect, useState } from "react";
import { Container, ListGroup, ListGroupItem, Button, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userActions, gamesActions } from "../_actions";
import gamesService from "../_services/games.service";

const LeaderBoardPage = (props) => {
    const users = useSelector((state) => state.users);
    const [userRecords, setUserRecords] = useState([]);
    const [currentActive, setCurrentActive] = useState(0);
    const [isLocked, setIsLocked] = useState(false);

    const dispatch = useDispatch();

    const user = useSelector((state) => state.authentication.user);
    const userWeekRecords = useSelector((state) => state.users.userWeekRecords)
    const weeks = useSelector((state) => state.games.weeks);



    useEffect(() => {
        dispatch(userActions.getAll());
        dispatch(userActions.getAllUserRecords());
        dispatch(gamesActions.getAllWeeks());

        if (users.items) {
            let sortedList = users.items.sort((a, b) => {
                return (b.wins - b.losses) - (a.wins - a.losses)
            })
            setUserRecords(sortedList);
        }
        setIsLocked(isWeekend());
    }, []);

    function isWeekend(date = new Date()) {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function sortUsers(users) {
        console.log(users.items)
        let sortedList = users.items.sort((a, b) => {
            if (b.wins - a.wins > 0) {
                return 1;
            } else if (a.wins - b.wins > 0) {
                return -1;
            } else if (a.losses - b.losses > 0) {
                return 1;
            } else if (b.losses - a.losses > 0) {
                return -1;
            } else if (b.weekWins - a.weekWins > 0) {
                return 1;
            } else {
                return -1;
            };
        })
        return sortedList
    }

    function loadGames() {
        gamesService.loadGames();
    }

    function displayDate(date) {
        let dateObj = new Date(date);
        return `${dateObj.getMonth() + 1}/${dateObj.getDate() + 1}`;
    }

    function displayUser(userIdArray) {
        let resultString = ""
        userIdArray.forEach((userId) => {
            const result = users.items.map(user => {
                if (userId === user.id) {
                    resultString += `${user.firstName} ${user.lastName} `
                }
            });
        });
        return resultString;
    }

    function displaySingleUser(userId) {
        const result = users.items.map(user => {
            if (userId === user.id) {
                return `${user.firstName} ${user.lastName}`
            }
        });
        return result
    }

    function handlePick(index) {
        setCurrentActive(index);
    }

    function showWeekRecords(index) {
        let weekRecords = [];
        userWeekRecords.forEach((record) => {
            if (record.week === (index + 1).toString()) {
                weekRecords.push(record);
            }
        })
        let sortedList = weekRecords.sort((a, b) => {
            return (b.wins - b.losses) - (a.wins - a.losses)
        })
        return sortedList
    }




    return (
        <div>
            {user.firstName === "Will" && user.lastName === "Cricchi" && <div>
                <Button variant="primary" disabled={isLocked} onClick={(e) => loadGames()}>Load Games</Button>
                {/* <Button variant="primary" onClick={(e) => submitPicks()}>Load Results</Button> */}
            </div>}
            {users.loading && <em>Loading users...</em>}
            {users.error && <span className="text-danger">ERROR: {users.error}</span>}
            {users.items &&
                <Container fluid className="main-container">
                    <ListGroup as="ul" className="recordsList">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>User</th>
                                    <th>Record</th>
                                    <th>Week Wins</th>
                                    <th>Net Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortUsers(users).map((user, index) => {
                                    return <tr key={user.id}>
                                        <td>#{index + 1}</td>
                                        <td>{user.firstName + ' ' + user.lastName}</td>
                                        <td>{user.wins}-{user.losses}</td>
                                        <td>{user.weekWins}</td>
                                        <td>{user.netEarnings < 0 ? `-$${user.netEarnings * -1}` : `$${user.netEarnings}`}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </ListGroup>
                </Container>
            }
            {weeks && users.items && <Container fluid className="main-container">
                <Row>
                    <Col md={3} sm={3} xs={3} className="weekCol">
                        <div>
                            <ListGroup as="ul">
                                {weeks.map((week, index) => {
                                    return <ListGroup.Item className="weekButton" as="li" active={index === currentActive} onClick={(e) => handlePick(index)} key={week.id}>
                                        <div>Week {week.weekNumber} - {displayDate(week.date)}: {displayUser(week.winnerId)}</div>
                                    </ListGroup.Item>
                                })}
                            </ListGroup>
                        </div>                    </Col>
                    <Col>
                        <Row>
                            {users.items && userWeekRecords &&
                                <Container fluid className="main-container">
                                    <ListGroup as="ul" className="recordsList">
                                        {showWeekRecords(currentActive).map((record, index) => {
                                            return <ListGroupItem as="li" key={index}>
                                                <div>#{index + 1} {displaySingleUser(record.userId)}: {record.wins} - {record.losses}</div>
                                            </ListGroupItem>
                                        }

                                        )}
                                    </ListGroup>
                                </Container>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>}
        </div>
    );


};


export default LeaderBoardPage;
